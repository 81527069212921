import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { Organization, SelectOrganizationBody, SelectOrgButton } from './style'
import { clearStorage } from '../../util'
import SmallLink from '../common/smallLink'
import UserSuspended from '../error/userSuspended'

const SelectOrganization = ({ organizations, authenticateToOrganization }) => {
  const [selectedOrg, setSelectedOrg] = useState(organizations[0]?.id)
  const [bypass, setBypass] = useState(false)
  const [disableAutoRedirect, setDisableAutoRedirect] = useState(false)
  const [justLogOut, setJustLogOut] = useState(false)
  const [toCreateOrganization, setToCreateOrganization] = useState(false)
  const [active, setActive] = useState(organizations[0] ? !!organizations[0].active : false)
  const [submit, setSubmit] = useState(false)
  const [orgInfo, setOrgInfo] = useState([])

  const redirectToOrganization = (appEndpoint, authKey) => {
    const { redirectWithApiKey, redirectTo } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    if (!redirectTo) {
      window.location.replace(
        `//${appEndpoint}/?${qs.stringify({ key: authKey, redirectWithApiKey })}`,
      )
    } else {
      window.location.replace(
        `/redirectIframe/${appEndpoint}/?${qs.stringify({ key: authKey, redirectTo })}`,
      )
    }
  }

  const orgPlan = (plan) => {
    switch (plan) {
      case 'Free Trial':
        return 'free'
      case 'Enterprise':
        return 'enterprise'
      case 'Startup':
        return 'startup'
      default:
        return 'unknown'
    }
  }

  const handleSelectOrganization = (id, status) => {
    if (!status) {
      setActive(false)
    } else {
      setActive(true)
    }
    setSelectedOrg(id)
  }

  const redirectCreateOrg = () => {
    setToCreateOrganization(true)
  }

  const handleSubmitOrg = async (to) => {
    if (!active) {
      setSubmit(true)
      return
    }
    const organizationId = to || selectedOrg
    const result = await authenticateToOrganization({
      variables: {
        organizationId,
      },
    })
    if (result.data?.authenticateToOrganization) {
      const { appEndpoint, authKey } = result.data?.authenticateToOrganization
      redirectToOrganization(appEndpoint, authKey)
    } else {
      clearStorage()
    }
  }

  useEffect(() => {
    setOrgInfo(organizations.filter((org) => org.id === selectedOrg))
  }, [selectedOrg])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const to = query.get('to')
    if (query.get('logout')) {
      clearStorage()
      setJustLogOut(true)
    } else if (to) {
      if (to === 'select') {
        setDisableAutoRedirect(true)
      } else {
        setBypass(true)
        handleSubmitOrg(parseInt(query.get('to'), 10))
      }
    } else if (organizations.length === 1) {
      const { orgId } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      handleSubmitOrg(orgId)
    }
  }, [])

  if (!active && submit) {
    return <UserSuspended orgInfo={orgInfo} setActive={setActive} />
  }
  return (
    <SelectOrganizationBody>
      {justLogOut && <Redirect to="/" />}
      {toCreateOrganization && <Redirect to="/createOrganization" />}
      {!organizations ||
        (organizations.length <= 0 && <Redirect to={{ pathname: '/noneOrganization' }} />)}
      {(organizations.length > 1 && !bypass) || disableAutoRedirect ? (
        <>
          <h1 className="org-select-title">Select Organization</h1>
          <div className="org-list">
            {organizations.map((org) => (
              <Organization
                key={org.id}
                className={selectedOrg === org.id ? 'selected' : ''}
                onClick={() => handleSelectOrganization(org.id, org.active)}
              >
                <span className="select-org" />
                <span className="select-org-name">{org.name}</span>
                <div className={`select-org-plan ${orgPlan(org.plan)}`}>{org.plan}</div>
                <div className="select-org-days">
                  {org.day || '-'} day
                  {org.day > 1 ? 's' : ''}
                </div>
              </Organization>
            ))}
          </div>
          <SelectOrgButton onClick={() => handleSubmitOrg()}>Select</SelectOrgButton>
          <SmallLink text="+ New Organization" action={redirectCreateOrg} />
        </>
      ) : null}
    </SelectOrganizationBody>
  )
}

SelectOrganization.propTypes = {
  organizations: PropTypes.array.isRequired,
  authenticateToOrganization: PropTypes.func.isRequired,
}

export default SelectOrganization
