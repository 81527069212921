import gql from 'graphql-tag'

export const autoCompleteOrganization = gql`
  query autoCompleteOrganization($name: String) {
    autoCompleteOrganization(name: $name) {
      id
      name
    }
  }
`

export const createOrganizationMutation = gql`
  mutation createOrganizationMutation($organizationInput: OrganizationInput) {
    createOrganization(organizationInput: $organizationInput) {
      id
      name
    }
  }
`

export const organizationConfigsQuery = gql`
  query organizationConfigsQuery {
    me {
      id
      name
      role
    }
    organizationConfigs {
      id
      action
      desc
      remark
      type
      choice
      value
      moreDesc
    }
  }
`

export const organizationConfigQuery = gql`
  query organizationConfigQuery($action: String) {
    organizationConfig(action: $action) {
      id
      action
      desc
      remark
      type
      choice
      value
    }
  }
`

export const organizationConfigMutation = gql`
  mutation organizationConfigMutation($configInput: [OrganizationConfigInput]) {
    setOrganizationConfig(configInput: $configInput) {
      action
      value
    }
  }
`

export const organizationFeatureQuery = gql`
  query organizationFeature {
    organizationFeature {
      masterProduct
      multiBoard
      quotation
      disableMonthSummary
    }
  }
`

export const organizationQuery = gql`
  query organization($id: Int) {
    organization(id: $id) {
      id
      name
    }
  }
`

export const shouldShowMonthlySummaryQuery = gql`
  query shouldShowMonthlySummary($pipelineId: Int) {
    shouldShowMonthlySummary(pipelineId: $pipelineId)
  }
`

const orgInfoInput = `  id
      companyName
      taxId
      imageUrl
      address
      subDistrict
      district
      province
      country
      postalCode
      email
      tel
      fax
`
export const organizationInfoQuery = gql`
  query organizationInfos {
    organizationInfos{
      ${orgInfoInput}
    }
  }

`

export const organizationInfoMutation = gql`
  mutation setOrganizationInfo($organizationInfoInput: OrganizationInfoInput) {
    setOrganizationInfo(organizationInfoInput: $organizationInfoInput) {
      ${orgInfoInput}
    }
  }
`

export const connectPeak = gql`
  mutation connectPeak($peakUserToken: String){
    connectPeak(peakUserToken:$peakUserToken) {
      id
      action
      desc
      remark
      type
      choice
      value
    }
  }
`

export const getPlan = gql`
  query packagePrice {
    packagePrice {
      id
      key
      name
      type
      price
      unit
    }
  }
`

export const createPaymentReferenceMutation = gql`
  mutation createPaymentReference($paymentReferenceInput:PaymentReferenceInput) {
    createPaymentReference(paymentReferenceInput: $paymentReferenceInput) {
      id
      refId
    }
  }
`

export const updatePaymentReferenceMutation = gql`
  mutation updatePaymentReference($paymentReferenceInput:PaymentReferenceInput) {
    updatePaymentReference(paymentReferenceInput: $paymentReferenceInput) {
      id
      refId
    }
  }
`

export const getPaymentReference = gql`
  query paymentReference($refId: String) {
    paymentReference(refId: $refId) {
      id
      refId
      priceBeforeVat
      vat
      totalPrice
      userCount
      taxInvoiceEmail
      status
      packagePrice {
        id
        key
        name
        type
        price
        unit
      }
    }
  }
`

export const getPaymentHistory = gql`
  query paymentHistory {
    organization {
      activeUser
    }
    paymentHistory {
      id
      nextPaymentPrice
      nextPaymentDate
      paymentReferences {
        id
        userCount
        expiredAt
        paymentAt
        taxInvoiceId
        priceBeforeVat
        packageDuration {
          year
          month
        }
        packagePrice {
          id
          name
          price
        }
      }
    }
  }
`

export const changeOrganizationMutation = gql`
  mutation changeOrganization($token: String!) {
    changeOrganization(token: $token)
  }
`

export const authenticateToOrganizationMutation = gql`
  mutation authenticateToOrganization($organizationId: Int) {
    authenticateToOrganization(organizationId: $organizationId) {
      authKey
      appEndpoint
      error
      message
    }
  }
`

export const verifyToOrganizationMutation = gql`
  mutation verifyToOrganization($key: String!) {
    verifyOrganizationKey(key: $key) {
      token
      refreshToken
    }
  }
`

export const userOrganizationsQuery = gql`
  query {
    userOrganizations {
      id
      name
      plan
      day
      active
      subDomain
      appEndpoint
      admins{
        id
        name
        email
        imageUrl
      }
    }
  }
`
