import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import React, { Component } from 'react'
import { IntercomProvider } from 'react-use-intercom'

import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'
import Error404 from './components/error/404'
import Error500 from './components/error/500'
import LoginPage from './pages/LoginPage'
import withTrackPage from './components/analytics/withTrackPage'
import ForgetPassword from './pages/ForgetPasswordPage'
import ResetPassword from './pages/RestPasswordPage'
import LineFreshToken from './components/line/lineFreshToken'
import LineLogin from './pages/LineLogin'
import SignupPage from './pages/SignupPage'
import SignupFullPage from './pages/SignupFullPage'
import CreateOrganizationPage from './pages/CreateOrganizationPage'
import NoneOrganizationPage from './pages/NoneOrganization'
import SelectOrganization from './pages/SelectOrganizationPage'
import OAuthCallbackPage from './pages/OAuthCallbackPage'
import ErrorLinkExpired from './components/error/linkExpired'
import UserSuspended from './components/error/userSuspended'
import LanguageSetter from './components/languageSetter'
import ProcessInvitePage from './pages/ProcessInvitePage'
import FreeTrailExpried from './pages/FreeTrailExpriedPage'
import RedirectTo from './pages/Redirect'
import RedirectIframe from './pages/RedirectIframe'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: {},
      isLoading: true,
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.initialize)
  }

  initialize = () => {
    window.liff.init(
      { liffId: process.env.REACT_APP_LINE_LIFF_ID },
      async () => {
        const lineContext = window.liff.getContext()
        this.setState({
          profile: lineContext,
          isLoading: false,
        })
      }, () => {
        this.setState({ isLoading: false })
      },
    )
  }

  render() {
    const { isLoading, profile } = this.state
    return isLoading ? (
      <LineLogin />
    ) : (
      <BrowserRouter>
        <LineFreshToken profile={profile}>
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID} autoBoot={true}>
          <LanguageSetter>
            <>
              <Switch>

                {/* LINE */}
                <Route path="/redirect" component={RedirectTo} />
                <Route path="/redirectIframe" component={RedirectIframe} />

                {/* Authenticated by OAUTH */}
                <Route path="/oauth/:provider" component={OAuthCallbackPage} />

                {/* SIGN UP */}
                <Route
                  path="/signup"
                  component={withTrackPage(SignupPage, 'Signup New', 'App Activity')}
                />
                <Route
                  path="/signupFull/:token"
                  component={withTrackPage(SignupFullPage, 'Signup 2nd Step', 'App Activity')}
                />

                {/* Invitation */}
                <Route
                  path="/invite/:organizationId/:inviteToken"
                  component={withTrackPage(ProcessInvitePage, 'Invitation', 'App Activity')}
                />

                {/* Forget & Reset Password */}
                <Route
                  path="/forgetpassword"
                  component={withTrackPage(ForgetPassword, 'Forgot Password', 'App Activity')}
                />
                <Route
                  path="/resetpassword/:token"
                  component={withTrackPage(ResetPassword, 'Reset Password', 'App Activity')}
                />

                {/* User Authenticated */}
                <Route
                  path="/selectOrganization"
                  component={withTrackPage(
                    SelectOrganization, 'Select Organization', 'App Activity',
                  )}
                />
                <Route
                  path="/createOrganization"
                  component={withTrackPage(
                    CreateOrganizationPage, 'Create Organization', 'App Activity',
                  )}
                />
                <Route
                  path="/noneOrganization"
                  component={withTrackPage(
                    NoneOrganizationPage, 'None Organization', 'App Activity',
                  )}
                />

                {/* Error */}
                <Route
                  path="/networkError"
                  component={withTrackPage(Error500, 'Network Error', 'App Activity')}
                />
                <Route
                  path="/notfoundError"
                  component={withTrackPage(Error404, 'Not Found', 'App Activity')}
                />
                <Route
                  path="/linkExpired"
                  component={withTrackPage(ErrorLinkExpired, 'Link expired', 'App Activity')}
                />
                <Route path="/userSuspended" component={withTrackPage(UserSuspended, 'User Suspended', 'App Activity')} />
                <Route
                  path='/freeTrailExpried'
                  component={withTrackPage(FreeTrailExpried, 'Free trail expried', 'App Activity')}
                />
                <Route
                  path="/"
                  render={() => {
                    if (localStorage.getItem('token')) {
                      return <Redirect to={`/selectOrganization${window.location.search}`/* TODO check if should redirect to aiya */} />
                    }
                    return (
                      <Route
                        component={withTrackPage(LoginPage, 'Sign In', 'App Activity')}
                      />
                    )
                  }}
                />
              </Switch>
            </>
          </LanguageSetter>
          </IntercomProvider>
        </LineFreshToken>
      </BrowserRouter>
    )
  }
}

export default App
